.portfolio-tabs {
    &__list {
        margin: 2rem 0 2.5rem;
        background-color: var(--text-color-lighten);
        padding: 16px;
        border-radius: 0.75rem;
        display: flex;
        justify-content: space-between;
        column-gap: 0.5rem;

        @media screen and (min-width: 576px) {
            // width: 332px;
            // max-width: 600px;
            // margin: 3rem auto;
        }
    }

    &__list>&__item {
        cursor: pointer;
        padding: 16px 24px;
    }

    &__item {
        width: 100%;
        border: none;
        outline: none;
        // padding: 1rem;
        color: var(--title-color);
        font-size: var(--small-font-size);
        font-family: var(--body-font);
        font-weight: 500;
        border-radius: 0.75rem;
        background-color: transparent;
        transition: .3s;
        text-align: center;

        &:hover {
            background-color: var(--container-color);
        }

        &.active {
            background-color: var(--body-color);
        }
    }
}

.tabs__content {
    .portfolio-tabs {
        &__item {
            display: none;

            &.active {
                display: block;
            }
        }
    }
}

.dark-theme {
    .portfolio-tabs {
        &__list {
            background-color: var(--container-color);
        }

        &__item {
            &:hover {
                background-color: var(--body-color);
            }
        }
    }
}