.projects {
    &__card {
        position: relative;
        border-radius: 1rem;
        overflow: hidden;
        // margin-bottom: 30px;
        width: calc(100% / var(--columns) - var(--row-gap) + var(--row-gap) / var(--columns));
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        & img {
            width: 100%;
            // height: 100%;
        }

        &:hover .projects__info {
            bottom: 0;
        }
    }

    &__tags {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 6px;
        margin-bottom: 12px;

        .tag_item {
            background: #fff;
            color: #212121;
            padding: 3px 6px;
            border-radius: 6px;
            font-size: 12px;
        }
    }

    &__desc {
        color: #212121;
        font-size: 12px;
        margin-bottom: 12px;
    }

    &__buttons {
        display: flex;
        justify-content: center;
        gap: 12px;
    }

    &__button.button {
        padding: 12px;
        border-radius: 50%;
        background-color: #fff;
        color: #212121;
        cursor: pointer;
        border: none;

        &:hover {
            background-color: #FFE071;
            color: #fff;
        }

        path {
            fill: currentColor;
        }
    }

    &__info {
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: -100%;
        left: 0;
        // background: linear-gradient(180deg, rgba(255, 224, 112, 0.3) 0%, #ffe070 95%);
        background: rgba(255, 224, 112, 0.8);
        display: flex;
        flex-direction: column;
        // align-items: flex-end;
        padding: 1.5rem 1.25rem;
        transition: .3s;
        text-align: left;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &__subtitle,
    &__title {
        // color: #212121;
    }

    &__title {
        font-size: 18px;
        margin-bottom: 12px;
        --title-color: var(--body-color);
    }

    &__content {
        --col-gap: 24px;
        --row-gap: 24px;
        --columns: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @media screen and (min-width: 767px) {
        &__content {
            --columns: 2;
            gap: var(--col-gap) var(--row-gap);
            // max-width: 720px;
            // margin: 0 auto;
        }
    }
}