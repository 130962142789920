@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/*=============== VARIABLES CSS ===============*/
:root {
    /*========== Colors ==========*/
    --first-color: #FFE071;
    --first-color-alt: #e3c24c;
    /* -4% */
    --title-color: #22272b;
    --text-color: #657481;
    --text-color-light: #bac0c4;
    --text-color-lighten: #e9ebec;
    --body-color: #fafdff;
    --container-color: #fff;

    /*========== Font and typography ==========*/
    --body-font: 'Poppins', sans-serif;

    --h2-font-size: 16px;
    --h3-font-size: 14px;
    --normal-font-size: 14px;
    --small-font-size: 13px;
    --smaller-font-size: 12px;

    // Responsive typography
    @media screen and (min-width: 968px) {
        --h2-font-size: 24px;
        --h3-font-size: 16px;
        --normal-font-size: 16px;
        --small-font-size: 15px;
        --smaller-font-size: 14px;
    }
}