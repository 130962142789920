.skills {
    &__content {
        display: flex;
        // justify-content: space-around;
        justify-content: space-between;
        flex-wrap: wrap;
        --columns: 1;
        --row-gap: 24px;
        row-gap: var(--row-gap);

        @media screen and (min-width: 992px) {
            --columns: 2;
            // --row-gap: 42px;
        }
    }

    &__item {
        width: 100%;
        width: calc(100% / var(--columns) - var(--row-gap) + var(--row-gap) / var(--columns));

        &__title {
            font-size: var(--h3-font-size);
            // text-align: center;
            text-align: left;
            margin-bottom: 1.5rem;

            @media screen and (min-width: 992px) {

                font-size: var(--normal-font-size);
                margin-bottom: 24px;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            --columns: 1;
            --col-gap: 24px;
            --row-gap: 16px;
            row-gap: var(--row-gap);

            &__item {
                // width: 40%;
                display: flex;
                text-align: left;
                // margin-bottom: 15px;
                width: calc(100% / var(--columns) - var(--col-gap) + var(--col-gap) / var(--columns));

                &__icon {
                    margin-right: 10px;
                }

                &__name {
                    font-size: var(--normal-font-size);
                    font-weight: 500;
                    line-height: 18px;
                }

                &__level {
                    font-size: var(--smaller-font-size);
                }
            }
        }
    }
}