.project__modal {
    .content {
        --gap: 20px;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: var(--gap);

        &__left,
        &__right {
            width: 100%;
        }

        &__left {
            background-color: #fff;
        }

        &__right {
            max-height: 500px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            text-align: left;

            .project {
                &__title {
                    text-align: center;
                }

                &__tags {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 8px;

                    .tag_item {
                        background: #fff;
                        color: #212121;
                        padding: 3px 6px;
                        border-radius: 6px;
                        font-size: 12px;
                    }
                }

                &__desc {
                    ul {

                        //inherit default styles
                        li {
                            list-style: disc;
                            margin-left: 1rem;
                        }
                    }
                }
            }
        }

        @media screen and (min-width: 768px) {
            &__left {
                width: calc(70% - calc(var(--gap) / 2));
            }

            &__right {
                width: calc(30% - calc(var(--gap) / 2));
            }

        }
    }
}