@import 'variable';

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  transition: .3s;
  /* For animation dark mode */
}

h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: 600;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: currentColor;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  width: 100%;
  // max-width: 760px;
  max-width: 600px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 8px;
  padding-left: 8px;
}

.overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 999;

  &.active {
    visibility: visible;
    opacity: 1;
  }
}

/*=============== BUTTONS ===============*/
.button {
  display: inline-flex;
  align-items: center;
  column-gap: .5rem;
  background-color: var(--first-color);
  color: #000;
  padding: 1rem 1.5rem;
  border-radius: .5rem;
  transition: .3s;
  box-shadow: 0 8px 24px RGBA(66, 165, 245, 0.24);
  --title-color: currentColor;

  & i {
    font-size: 1.25rem;
  }

  &:hover {
    background-color: var(--first-color-alt);
  }

  &__small {
    padding: 1rem;
    box-shadow: none;
  }

  &__gray {
    background-color: var(--text-color-lighten);
    color: var(--title-color);

    &:hover {
      background-color: var(--text-color-light);
    }
  }
}

/*=============== THEME ===============*/
.change-theme {
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  // right: 0;
  font-size: 1.25rem;
  color: var(--title-color);
  cursor: pointer;
  text-align: center;
  transition: .3s;

  &:hover {
    color: var(--first-color);
  }

}

/*========== Variables Dark theme ==========*/
body.dark-theme {
  --title-color: #f1f2f4;
  --text-color: #b8c0c7;
  --body-color: #0c151d;
  --container-color: #171f26;

  .profile {

    .change-theme svg path,
    .profile__social-item svg path,
    .profile__buttons svg path {
      fill: var(--title-color)
    }
  }
}

/*========== 
  Color changes in some parts of 
  the website, in light theme
==========*/

.dark-theme .button__gray {
  background-color: var(--container-color);

  &:hover {
    background-color: #1f2a33;
  }
}

.dark-theme .filters__content {
  background-color: var(--container-color);
}

.dark-theme::-webkit-scrollbar {
  background-color: #26292c;
}

.dark-theme::-webkit-scrollbar-thumb {
  background-color: #383e42;
}

.dark-theme::-webkit-scrollbar-thumb:hover {
  background-color: #4b5258;
}