.profile {
    position: relative;
    padding-top: 3.5rem;

    &__container {
        row-gap: 2rem;
    }

    &__data {
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__perfil {
        & img {
            width: 75px;
        }

        width: 100px;
        height: 100px;
        background: #FFE071;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        border: 5px solid #fff;
    }

    &__border {
        border: 3.5px solid #FFE071;
        justify-self: center;
        width: 115px;
        height: 115px;
        border-radius: 50%;
        display: grid;
        place-items: center;
        margin-bottom: .75rem;
    }

    &__name {
        font-size: var(--h2-font-size);
    }

    &__profession {
        font-size: var(--smaller-font-size);
        font-weight: 500;
        color: var(--text-color);
        margin-bottom: 12px;
    }

    &__social {
        display: flex;
        justify-content: center;

        &-item {
            padding: 0 10px;

            &:hover svg path {
                fill: var(--first-color-alt);
            }
        }

        &-link {
            font-size: 1.25rem;
            color: var(--title-color);
            transition: .3s;

            &:hover {
                color: var(--first-color);
            }
        }
    }

    &__info {
        display: flex;
        // justify-content: space-between;
        justify-content: center;
        margin-top: 30px;
        gap: 16px;

        &-group {
            text-align: center;
        }

        &-number {
            font-size: var(--normal-font-size);
            margin-bottom: .25rem;
        }

        &-description {
            font-size: var(--smaller-font-size);
            font-weight: 500;
        }
    }

    &__buttons,
    &__buttons-small {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
    }

    &__buttons {
        margin-top: 30px;
    }

    &__buttons-small {
        //    margin-left: 10px;
    }
}

@media screen and (max-width: 320px) {
    .profile {
        &__buttons {
            flex-direction: row;
        }
    }
}

/* For large devices */
@media screen and (min-width: 992px) {

    .change-theme {
        top: 2.5rem;
        right: 2.5rem;
    }

    .profile {
        padding-top: 4rem;

        &__border {
            width: 135px;
            height: 135px;
            margin-bottom: 1rem;
        }

        &__perfil {
            width: 120px;
            height: 120px;

            & img {
                width: 90px;
            }
        }

        &__profession {
            margin-bottom: 1.5rem;
        }

        &__info {
            // column-gap: 3rem;
            justify-content: center;

            &-group {
                min-width: 120px;
            }

            &-description {
                font-size: var(--small-font-size);
            }
        }

        &__buttons {
            // column-gap: 2rem;
        }
    }

    .projects {
        &__content {
            // gap: 2rem 3rem;
        }

        &__modal {
            padding: 1.5rem;
        }
    }
}